/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import CoreColumn from '../core-column';

const CoreColumns = ({ attrs, innerBlocks, reusableBlocks, parsedContent, content }) => {
	if (!Array.isArray(innerBlocks)) {
		console.warn('Columns should have innerBlocks');
		return null;
	}


	const cols = innerBlocks.length;

	const columns = innerBlocks.map((col, ci) => {
		return (
			<CoreColumn key={ci} {...col} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />
		)
	});

	const columnsClassName = ['wp-block-columns', `has-${cols}-columns`, attrs?.className];

	if (attrs.verticalAlignment) {
		columnsClassName.push(`is-vertically-aligned-${attrs.verticalAlignment}`);
	}

	return (
		<div className={classnames(columnsClassName)}>{columns}</div>
	);
};

export default CoreColumns;
