/**
 * External dependencies.
 */
import React from 'react';
import classnames from 'classnames';
// import { WPGBlock } from 'react-gutenberg';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';

const CoreColumn = ({ attrs, innerBlocks, reusableBlocks, parsedContent, content }) => {

    if (!Array.isArray(innerBlocks)) {
        console.warn('Column should have innerBlocks');
        return null;
    }
    let columnBlocks = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);
    // console.log(group)

    // let columnBlocks = innerBlocks.map((block, ci) => {
    //     return <WPGBlock key={ci} block={block} />;
    // });

    const columnClassName = [
        'wp-block-column',
        attrs?.className,
    ];

    if (attrs && attrs.verticalAlignment) {
        columnClassName.push(`is-vertically-aligned-${attrs.verticalAlignment}`)
    }

    const style = {};

    if (attrs.width) {
        style.flexBasis = attrs.width;
    }

    if (attrs.additionalAttributes) {
        const {
            allowWrapper,
            defaultWrapperClass,
            isWrapperLink,
            linkAddress,
            linkAddressClass,
        } = attrs.additionalAttributes;

        if (allowWrapper) {
            columnBlocks = <div className={defaultWrapperClass}> {columnBlocks} </div>;
            if (isWrapperLink) {
                columnBlocks = <a href={linkAddress} className={linkAddressClass}> {columnBlocks} </a>;
            }
        }
    }

    return (
        <div className={classnames(columnClassName)} style={style}>{columnBlocks}</div>
    );
};

export default CoreColumn;
