/**
 * External dependencies.
 */
import React from 'react';
import classname from 'classnames';
import { WPGBlock } from 'react-gutenberg';

import './style.scss';

const CoreGroup = ({ attrs, innerBlocks }) => {
	const { columns } = attrs;



	if (!Array.isArray(innerBlocks)) {
		console.warn('Group should have innerBlocks');
		return null;
	}

	const { className, additionalAttributes } = attrs;
	let group = innerBlocks.map((innerBlock, index) => {
		return (
			<WPGBlock
				key={`${innerBlock.name}-${index}`}
				block={innerBlock}
			/>
		);
	});

	const classNames = {
		'wp-block-group alignwide': true,
		'wp-block-gallery': true,
		[className]: className
	};

	//flex-basis: 33.33%;
	if (columns) {
		classNames[`columns-${columns}`] = true
	}

	const groupClassNames = classname(classNames);


	if (additionalAttributes) {
		const {
			allowWrapper,
			defaultWrapperClass,
			isWrapperLink,
			linkAddress,
			linkAddressClass,
		} = additionalAttributes;

		if (allowWrapper) {
			group = <div className={defaultWrapperClass}> {group} </div>;
			if (isWrapperLink) {
				group = <a href={linkAddress} className={linkAddressClass}> {group} </a>;
			}
		}

	}

	return (
		<div className={groupClassNames}>
			{group}
		</div>
	)
};

export default CoreGroup;
