/**
 * React dependencies
 */
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick";

/**
 * Internal dependencies
 */
import './style.scss';

const MobileScreenSlider = (props) => {
  const { attrs, innerHTML } = props;

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const containerClassName = classname({
    'wp-block-ow-mobile-screen-block-slider-custom': true,
  });

  const sliderRef = createRef();
  const { images, amount, showArrows, desktop, tablet, mobile } = attrs;

  useEffect(() => {
    if (inView) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
  }, [inView]); // eslint-disable-line

  useEffect(() => {
    const html = document.createElement('div');

    html.innerHTML = innerHTML;
  }, [innerHTML]);


  const slidesDesktop = desktop ? parseFloat(desktop) : 3;
  const slidesTablet = tablet ? parseFloat(tablet) : 2;
  const slidesMobile = mobile ? parseFloat(mobile) : 1;

  const settings = {
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    autoplaySpeed: 5000, // Change slide after 5 seconds.
    slidesToShow: amount || 2,
    slidesToScroll: 1,
    centerPadding: '0',
    // margin: 30,
    // customPaging: 30,
    // swipeToSlide: true,
    touchThreshold: 10,
    responsive: [

      {
        breakpoint: 767,
        settings: {
          slidesToShow: slidesMobile,
          slidesToScroll: parseInt(slidesMobile),
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesTablet,
          slidesToScroll: parseInt(slidesTablet),
        }
      },
      {
        breakpoint: 3840,
        settings: {
          slidesToShow: slidesDesktop,
          slidesToScroll: parseInt(slidesDesktop),
        }
      },
    ]
  };

  return (
    <section ref={ref} className={containerClassName}>
      <div className="ow-features-mobile-screen-slider">

        <Slider {...settings} ref={sliderRef} >
          {
            images.map((image, index) => {
              return (
                <div className="slider-item-wrapper" key={index}>
                  <img src={image} width={250} alt='' />
                </div>
              );
            })
          }
        </Slider>
        {showArrows && (<div className='arrows-contrainer'>
          <button className="ow-connect__arrows arrow-revert" onClick={() => sliderRef.current.slickPrev()} >
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="29" viewBox="0 0 74 29">
              <g fill="none" fillRule="evenodd">
                <g stroke="#000">
                  <g>
                    <path d="M0 13.435L73 13.435" transform="translate(-20.000000, -1564.000000) translate(20.000000, 1565.064971)" />
                    <path d="M50 22.935L50 3.935 69 3.935" transform="translate(-20.000000, -1564.000000) translate(20.000000, 1565.064971) translate(59.500000, 13.435029) rotate(135.000000) translate(-59.500000, -13.435029)" />
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <button className="ow-connect__arrows" onClick={() => sliderRef.current.slickNext()} >
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="29" viewBox="0 0 74 29">
              <g fill="none" fillRule="evenodd">
                <g stroke="#000">
                  <g>
                    <path d="M0 13.435L73 13.435" transform="translate(-20.000000, -1564.000000) translate(20.000000, 1565.064971)" />
                    <path d="M50 22.935L50 3.935 69 3.935" transform="translate(-20.000000, -1564.000000) translate(20.000000, 1565.064971) translate(59.500000, 13.435029) rotate(135.000000) translate(-59.500000, -13.435029)" />
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>)}
      </div>
    </section>
  );
}

MobileScreenSlider.propTypes = {
  innerHTML: PropTypes.string
}

export default MobileScreenSlider;

