/**
 * React dependencies
 */
import React, { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import './style.scss';
import { useDevice } from '../../../hooks';
import { isEmpty } from 'lodash';


const Tabs = (props) => {
	const { attrs } = props;
	const { tabs, showOnMobile = true, tabsBehaviour = true, className } = attrs;
	const shouldDisplay = (!('mobile' === useDevice()) || showOnMobile);
	const [activeIndex, setActiveIndex] = useState(0);
	const [shouldScroll, setScroll] = useState(false);

	useEffect(() => { // On mount, set the scroll margin for every section to 100px
		if (shouldDisplay) {
			tabs.forEach((tab) => {
				if (!tabsBehaviour) {
					const element = document.querySelector(`.${tab.selector}`);
					if (element) element.style['scroll-margin-top'] = '100px';
				}
			});
		}
	}, [tabs, tabsBehaviour, shouldDisplay]);

	useEffect(() => { // on update of the active index, we either scroll or fade in the contents based on tabsBehaviour
		if (shouldDisplay) {
			tabs.forEach((tab, index) => {
				const element = document.querySelector(`.${tab.selector}`);
				if (isEmpty(element)) {
					return;
				}

				if (tabsBehaviour) {
					element.style.display = (index === activeIndex) ? 'block' : 'none';

				} else {
					if (index === activeIndex && shouldScroll) {
						element.scrollIntoView(true);
					}
				}
			});
		}
	}, [activeIndex, tabs, tabsBehaviour, shouldDisplay, shouldScroll]);

	const clickHandler = (index) => {
		setScroll(true);
		setActiveIndex(index);
	}

	return (
		<div className={`tabs ${className}`}>
			{ shouldDisplay && 
				tabs.map((tab, index) => {
					const classes = ['tab-button'];
					if (index === activeIndex) {
						classes.push('active');
					}
					return <button key={index} className={`${classes.join(' ')}`} onClick={() => clickHandler(index)}>
						{tab.name}
					</button>;
				})
			}
		</div>
	);

};

export default Tabs;
