/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Image from './../../image/index';
import { getFormattedDate, sanitize, postSlug } from '../../../utils/functions';

import './style.scss';
import { Link } from "gatsby";

/**
 * PostCard Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const PostCard = ({ post, attrs }) => {
	const { category_names, author_name, featuredImageInfo, date, title } = post;
	const {
		displayCategory,
		addLinkToFeaturedImage,
		displayPostDate,
		displayAuthor,
		displayFeaturedImage,
	} = attrs;

	const containerClassName = classnames(
		'article-card'
	);

	const featuredImage = {
		altText: featuredImageInfo?.alt,
		sourceUrl: featuredImageInfo?.url
	};

	return (
		<div className='blog-list__item'>
			{addLinkToFeaturedImage &&
				<Link className="blog-list__card-link" to={postSlug(post.slug)}>
					<article className={containerClassName}>

						{displayFeaturedImage &&
							<figure className="article-card__header">
								<Image {...featuredImage} />
							</figure>
						}

						<div className="article-card__footer">
							<div className="article-card__footer-top">

								{displayCategory && !isEmpty(category_names) ? (
									category_names.map((category, index) => (
										<Fragment key={`term-${index}`}>
											<span className="article-card__term">{category}</span>
											{/* {getComma(category, index)} */}
											{index !== category_names.length - 1 ? ', ' : ''}
										</Fragment>
									))
								) : null}

								<h2 className="article-card__title" dangerouslySetInnerHTML={{ __html: sanitize(title.rendered) }} />
							</div>

							<div className="article-card__meta">
								{displayPostDate &&
									<time className="article-card__posted-on">{getFormattedDate(date)}</time>
								}

								{displayAuthor && author_name ? (
									<span className="article-card__author">
										<span className="article-card__by"> By </span>
										<span className="article-card__posted-by">{author_name}</span>
									</span>
								) : null}
							</div>
						</div>
					</article>
				</Link>
			}
		</div>
	);
};

PostCard.propTypes = {
	attrs: PropTypes.object,
	posts: PropTypes.object,
}

export default PostCard;
