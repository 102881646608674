import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const CoreList = (props) => {
	const { innerBlocks } = props;
	if (!innerBlocks) {
		return null;
	}
	return (<ul>
		{innerBlocks?.map(item => ReactHtmlParser(item.innerHTML))}
	</ul>);
};

export default CoreList;