/**
 * React dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies
 */
import './style.scss';
import { sanitize } from "../../../utils/functions";
import ReactHubspotForm from '../../react-hubspot-form';

const PublisherContactForm = ({ attrs }) => {
	const [ref, inView] = useInView({
		threshold: 0.2,
		triggerOnce: true,
	});

	const defaultAttrs = {
		title: 'Let’s have a conversation.',
		portalId: '5731985',
		formId: '7b7241c1-3c38-4235-b002-a4772b18294b',
	};

	const {
		title, portalId, formId
	} = { ...defaultAttrs, ...attrs };

	const innerContainerClassNames = classname({
		'to-be-faded-in': !inView,
		'fade-in-section': inView
	});

	return (
		<section className="wp-block-ow-feature-block-publisher-contact-form conversation-form" id="contact-us">
			<div ref={ref} className={innerContainerClassNames}>
				<div className='row align-right'>
					<div className="large-8 medium-12 small-12 columns conversation-form__heading-wrap">
						<h2 className="conversation-form__heading" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
					</div>
					<div className="large-4 medium-6 small-12 columns">
						<div className="conversation-form__form openweb-hs-form">
							<ReactHubspotForm
								portalId={portalId}
								formId={formId}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

PublisherContactForm.propTypes = {
	attrs: PropTypes.object,
}

export default PublisherContactForm;
