/**
 * External dependencies.
 */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

/**
 * Internal dependencies
 */

const DefaultBlock = (props) => {
    const { innerHTML } = props;

    return (<>{ReactHtmlParser(innerHTML)}</>
    );
};

export default DefaultBlock;
