/**
 * React dependencies
 */
import React from 'react';
import { WPGBlock } from 'react-gutenberg';

/**
 * Internal dependencies
 */
import './style.scss';

const Buttons = (props) => {
	const { innerBlocks, innerHTML, attrs } = props;
	const style = {};
	const { layout } = attrs;
	if (layout) {
		if (layout.justifyContent) {
			style.justifyContent = layout.justifyContent;
		}
		if (layout.type) {
			style.display = layout.type;
		}
	}
	const classes = innerHTML.match(/class="(.*?)"/i)[1];
	const buttons = innerBlocks.filter(block => ('core/button' === block.blockName));


	return (
		<div className={classes} style={{ ...style }}>
			{
				buttons.map((button, index) => {
					return <div key={index}>
						<WPGBlock block={button} />
					</div>;
				})
			}
		</div>
	);
};

export default Buttons;
