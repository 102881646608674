import React from 'react';
import PropTypes from 'prop-types';
// import ReactHtmlParser from 'react-html-parser';
import classname from 'classnames';
import LottiePlayer from '@components/lottie-player';
import { useDevice } from '@hooks';

import './Lottie.scss';


export const Lottie = (props) => {
    const { attrs: { desktopLottieImage, mobileLottieImage, desktopLottieFile, mobileLottieFile, align } } = props;

    // const [ref, inView] = useInView({
    //     threshold: 0.1,
    //     triggerOnce: true,
    // });

    const className = classname({
        'ow-features-lottie': true,
        [`align${align}`]: true,
    });

    const lottieDevice = 'desktop' === useDevice() ? 'desktop' : 'mobile';
    const staticImage = 'desktop' === lottieDevice ? desktopLottieImage : mobileLottieImage;
    staticImage.sizes = `${staticImage.originalImageURL} 2000vw`;

    return (<section className={className} >
        <LottiePlayer
            className="lottie-animated-image"
            mobileJsonUrl={mobileLottieFile}
            desktopJsonUrl={desktopLottieFile}
            device={lottieDevice}
            loop={true}
            load={true}
        // staticImage={staticImage}
        />
    </section>
    );
};

Lottie.propTypes = {
    attrs: PropTypes.object
}
