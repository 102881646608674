import './style.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';

function generateUniqueIdWithPrefix(prefix) {
	const uniqueNumber = _.uniqueId();
	const randomString = Math.random().toString(36).substr(2, 10);
	const uniqueId = prefix + (uniqueNumber + randomString).slice(0, 10);
	return uniqueId;
}

const CoreVideo = (props) => {
	const [autoplay, setAutoplay] = useState(false);
	const id = generateUniqueIdWithPrefix("unique-id-");

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			const videoContainer = document.getElementById(id);
			if (videoContainer) {
				const video = videoContainer.querySelector('video');
				if (video && !autoplay && video.hasAttribute('autoplay')) {
					setAutoplay(video.hasAttribute('autoplay'));
				}
				if (video && autoplay) {
					if (screenWidth < 768) {
						video.removeAttribute('autoplay'); // Remove autoplay attribute
						video.pause(); // Pause the video if it's currently playing
					} else {
						video.setAttribute('autoplay', 'autoplay'); // Add autoplay attribute
						video.play(); // Play the video
					}
				}
			}
		};
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [autoplay]);

	const className = '';
	const { innerHTML } = props;
	const HtmlVideo = ReactHtmlParser(innerHTML);

	return (
		<div id={id} className={classnames('ow-video-player-container', className)}>
			{HtmlVideo}
		</div>
	);
};

export default CoreVideo;
