import React from 'react';
import PropTypes from 'prop-types';
// import ReactHtmlParser from 'react-html-parser';
import { useInView } from 'react-intersection-observer';
import classname from 'classnames';
import LottiePlayer from '@components/lottie-player';

import './AdvancedLottie.scss';


export const AdvancedLottie = (props) => {
    const { attrs } = props;
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });
    const { align, uniqueID, fileUrl } = attrs;

    const className = classname({
        'kb-lottie-container': true,
        [`align${align}`]: true,
        [`kb-lottie-${uniqueID}`]: true
    });


    return (<section ref={ref} className={className} >
        <LottiePlayer
            className="lottie-animated-image"
            desktopJsonUrl={fileUrl}
            loop={true}
            load={inView}
        />
    </section>
    );
};

AdvancedLottie.propTypes = {
    attrs: PropTypes.object
}
