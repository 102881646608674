import React from 'react';
import PropTypes from 'prop-types';

import './advancedHeaderStyles.scss';
import { ELEMENT_TYPES, getKadenceBlock, inlineStyles, ELEMENT_ATTRIBUTES } from '../utils';

export const AdvancedHeader = (props) => {
    //, attrs: { uniqueID }
    const { parsedContent } = props;

    const text = getKadenceBlock(props, ELEMENT_TYPES.TEXT, parsedContent);

    if (
        text
        // && text.component
        && ['h1', 'h2', 'h3'].includes(text.component.type)
        && text.component.props
        && text.component.props.children
        && text.component.props.children.length > 0
        && text.component.props.children[0].length > 0

    ) {
        const regex = /’/gi;
        text.component.props.children[0] = text.component.props.children[0].replace(regex, '\'') //.replaceAll('’', '\'');
    }
    // if (uniqueID === '_217dde-03') {
    //     // console.log(text)
    //     // console.log(props)
    // }

    if (!text.style) {
        text.style = inlineStyles(props, `${ELEMENT_ATTRIBUTES[ELEMENT_TYPES.TEXT].class_prefix}`);
    }

    return (<>
        {text.style}
        {text.component}
    </>
    );
};

AdvancedHeader.propTypes = {
    attrs: PropTypes.object
}
