/**
 * React dependencies
 */
 import React from 'react';
 import classname from 'classnames';
 import { useInView } from 'react-intersection-observer';

 /**
  * Internal dependencies
  */
 import './style.scss';

 const Quote = ( { innerHTML } ) => {
	const [ ref, inView ] = useInView( {
		threshold: 0.2,
		triggerOnce: true,
	} );

	const containerClassNames = classname( {
		'to-be-faded-in': ! inView,
		'fade-in-section': inView
	} );

     return (
         <div
            ref={ ref }
            className={ containerClassNames }
            dangerouslySetInnerHTML={ { __html: innerHTML } }
        />
     );
 };

 export default Quote;
