/**
 * React dependencies
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies
 */
import './style.scss';
import RightArrowIcon from '../../icons/right-arrow-icon';

const FeaturedInfo = ( { attrs, innerHTML } ) => {
	const [ ref, inView ] = useInView( {
		threshold: 0.3,
		triggerOnce: true,
	} );

	const containerClassName = classname( {
		'wp-block-ow-feature-block-featured-info': true,
		'to-be-faded-in': ! inView,
		'fade-in-section': inView
	} );

	const [ title, setTitle ] = useState( '' );
	const [ description, setDescription ] = useState( '' );

	const {
		linkTitle = 'Learn more about Experience',
		linkUrl,
		mediaUrl,
		iconUrl
	} = attrs;

	useEffect( () => {
		const html = document.createElement( 'div' );

		html.innerHTML = innerHTML;

		const titleNode = html.getElementsByClassName( 'featured-info-block__left__title' );
		const descriptionNode = html.getElementsByClassName( 'featured-info-block__left__desc' );

		setTitle( titleNode[0].innerText );
		setDescription( descriptionNode[0].innerText );
	}, [ innerHTML ] );

	return (
		<div ref={ ref } className={ containerClassName }>
			{ iconUrl &&
			<div className='featured-info-icon'>
				<img src={ iconUrl } alt={ title } />
			</div>
			}

			<div className='featured-info-block'>
				<div className='featured-info-block__left featured-info-block__item'>
					<h2 className='featured-info-block__left__title'>{ title }</h2>
					<p className='featured-info-block__left__desc'>{ description }</p>
					<a href={ linkUrl } className='featured-info-block__left__link'>{ linkTitle }
						<RightArrowIcon />
					</a>
				</div>

				{ mediaUrl &&
				<div className='featured-info-block__right featured-info-block__item'>
					<img src={ mediaUrl } alt={ title } />

					<a href={ linkUrl } className='featured-info-block__left__link hide-for-large featured-info-block__left__link__mobile'>{ linkTitle }
						<RightArrowIcon />
					</a>
				</div>
				}
			</div>
		</div>
	);
}

FeaturedInfo.propTypes = {
	innerHTML: PropTypes.string
}

export default FeaturedInfo;

