/**
 * External dependencies.
 */
import React from 'react';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';

import './style.scss';

const CoreCover = ({ attrs, innerBlocks }) => {
	const [ref, inView] = useInView({
		threshold: 0.1,
		triggerOnce: true,
	});
	const { className, additionalAttributes, url } = attrs;
	let group = collectInnerBlocks(innerBlocks)


	const coverClassNames = classname({
		'wp-block-cover aligncenter': true,
		[className]: className
	});

	const containerClassNames = classname({
		'wp-block-cover__inner-container': true,
		'to-be-faded-in': !inView,
		'fade-in-section': inView
	});


	if (additionalAttributes) {
		const {
			allowWrapper,
			defaultWrapperClass,
			isWrapperLink,
			linkAddress,
			linkAddressClass,
		} = additionalAttributes;

		if (allowWrapper) {
			group = <div className={defaultWrapperClass}> {group} </div>;
			if (isWrapperLink) {
				group = <a href={linkAddress} className={linkAddressClass}> {group} </a>;
			}
		}

	}
	return (
		<div className={coverClassNames}>
			{url && <img className="wp-block-cover__image-background" src={url} alt="" />}
			<div ref={ref} className={containerClassNames}>
				{group}
			</div>
		</div>
	)
};

export default CoreCover;
