import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { inlineStyles } from '../utils';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';
import './KadenceColumnStyles.scss';

export const KadenceColumn = (props) => {
    const { attrs, innerBlocks, reusableBlocks, parsedContent, content } = props;
    const { id, uniqueID, className } = attrs;
    const classInnerColumn = `inner-column-${id || 1}`;
    const classUniqeId = `kt-adv-column${uniqueID}`;

    const containerClassNames = classnames({
        [classUniqeId]: true,
        'wp-block-kadence-column': true,
        'kadence-column': true,
        [classInnerColumn]: classInnerColumn,
        [className]: className
    });

    let styles = null;
    let group = null;

    if (innerBlocks && innerBlocks.length > 0 && parsedContent && content) {
        group = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);
    }
    // console.log(props)
    styles = inlineStyles(props, 'kt-adv-column');

    return (
        <>
            {styles}
            {attrs && attrs.kadenceBlockCSS && ReactHtmlParser(`<style>${ReactHtmlParser(`${attrs.kadenceBlockCSS?.replace(new RegExp("selector", 'g'), "." + classUniqeId)}`)}</style>`)}
            <div
                id={uniqueID}
                className={containerClassNames}>
                {group}
            </div>
        </>
    );
};

KadenceColumn.propTypes = {
    attrs: PropTypes.object
}
