/**
 * External dependencies.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import ReactHubspotForm from '../../react-hubspot-form';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Home Partnership Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Partnership = ({ meta, data, isHome, attrs }) => {
	const {
		partnershipSectionTitle,
		formOneTitle,
		formTwoTitle,
		formOneFormId,
		formOnePortalid,
		formOneContent,
		formTwoPortalid,
		formTwoFormId,
		formTwoContent,
	} = meta;

	const tabsItems = [
		{
			isActive: true,
			tabmenu: formOneTitle,
			portalId: formOnePortalid,
			formId: formOneFormId,
			content: formOneContent,
		},
		{
			isActive: false,
			tabmenu: formTwoTitle,
			portalId: formTwoPortalid,
			formId: formTwoFormId,
			content: formTwoContent,
		},
	];

	const [tabsData, setTabsData] = useState(tabsItems);
	const [reRender, setReRender] = useState(false);

	/**
	 * Handle Tab click.
	 *
	 * @param {Object} event Event Obj
	 * @param {number} itemPos Item position.
	 */
	const handleTabClick = (event, itemPos) => {
		let newTabsData = tabsData;

		// Set the rest of the tabs to false and the one clicked to true.
		newTabsData.forEach((item, index) => item.isActive = itemPos === index);

		setTabsData(newTabsData);
		setReRender(!reRender);
	};

	const cfEventTag = attrs?.cfEventTag;
	const cfEventTagParent = attrs?.cfEventTagParent;
	const gtmEvent = attrs?.gtmEvent;

	return (
		<section className={isHome ? 'home-sections home-partnership partnership' : 'contact-section partnership'} id="contact-us">
			<div className="row">
				<div className="medium-8 small-12 columns">
					<h2 className="partnership__title" dangerouslySetInnerHTML={{ __html: sanitize(partnershipSectionTitle) }} />
					<ul className="partnership__tabs">
						{tabsData.map((tab, index) => (
							<li key={index} className={`partnership__tab ${tab.isActive ? 'active' : ''}`}>
								<button onClick={(event) => handleTabClick(event, index)} className="tabmenu">{tab.tabmenu}</button>
							</li>
						))}
					</ul>
					{tabsData.map((tab, index) => (
						<div key={`content-${tab.tabmenu}-${index}`} className={`partnership__text-content ${tab.isActive ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: sanitize(tab.content) }} />
					))}
				</div>
				<div className="medium-4 small-12 columns">
					<div className="partnership__tabcontent">
						{tabsData.map((tab, index) => {
							const tagParent = isHome ? 'HOME' : 'CONTACT';
							return (
								<div key={`${tab.tabmenu}-${index}`} className={`partnership__content ${tab.isActive ? 'active' : ''}`} >
									<ReactHubspotForm
										portalId={tab.portalId}
										formId={tab.formId}
										tagName={cfEventTag || "main_contact_us_form"}
										formAction={gtmEvent || 'contact_form_submit'}
										tagParent={cfEventTagParent ? `${cfEventTagParent} | ${tab.tabmenu}` : tab.tabmenu} // provide url of the page
									/>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	);

};

Partnership.propTypes = {
	meta: PropTypes.object,
	data: PropTypes.object,
	isHome: PropTypes.bool,
};

Partnership.defaultProps = {
	meta: {},
	data: {
		title: '',
		image: {}
	},
	isHome: true
};

export default Partnership;
