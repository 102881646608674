/**
 * External dependencies.
 */
import React from 'react';
import Proptypes from 'prop-types';
import useHubSpot from './useForm';
/**
 * ReactHubspotForm Component.
 *
 * @return {jsx}
 */
const ReactHubspotForm = ({ formId, portalId, setSubmitted, tagName, tagParent, formAction }) => {

	const handleFormSubmit = () => {
		if ('function' === typeof setSubmitted) {
			setSubmitted(true);
		}
		if ('undefined' !== typeof window.dataLayer) {
			const dataObj = {}

			if (tagParent !== undefined) {
				dataObj.cfEventTagParent = tagParent;
			}
			if (tagName !== undefined) {
				dataObj.cfEventTag = tagName;
			}
			if (formAction !== undefined) {
				dataObj.event = formAction;
			}
			window.dataLayer.push(dataObj);
		}

	}

	const { element } = useHubSpot({
		portalId: portalId,
		formId: formId,
		onFormSubmit: handleFormSubmit,
	});

	return element();
};

ReactHubspotForm.propTypes = {
	formId: Proptypes.string.isRequired,
	portalId: Proptypes.string.isRequired,
	tagName: Proptypes.string,
	tagParent: Proptypes.string
};

ReactHubspotForm.defaultProps = {
	tagName: '',
	tagParent: ''
}

export default ReactHubspotForm;
