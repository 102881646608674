import React from 'react';
import classname from 'classnames';
// import { WPGBlock } from 'react-gutenberg';
import { useInView } from 'react-intersection-observer';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';

import './style.scss';

const CoreGroup = ({
	innerBlocks, reusableBlocks, parsedContent, content,
	attrs: { className, additionalAttributes },
}) => {

	const [ref, inView] = useInView({
		threshold: 0.1,
		triggerOnce: true,
	});


	if (!Array.isArray(innerBlocks)) {
		console.warn('Group should have innerBlocks');
		return null;
	}


	let group = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);
	// console.log(group1)
	// group = innerBlocks.map((innerBlock, index) => {
	// 	return (
	// 		<WPGBlock
	// 			key={`${innerBlock.name}-${index}`}
	// 			block={innerBlock}
	// 		/>
	// 	);
	// });
	// console.log(innerBlocks)

	const groupClassNames = classname({
		'wp-block-group alignwide': true,
		[className]: className
	});

	const containerClassNames = classname({
		'wp-block-group__inner-container': true,
		'to-be-faded-in': !inView,
		'fade-in-section': inView
	});

	if (additionalAttributes) {
		const {
			allowWrapper,
			defaultWrapperClass,
			isWrapperLink,
			linkAddress,
			linkAddressClass,
		} = additionalAttributes;

		if (allowWrapper) {
			group = <div className={defaultWrapperClass}> {group} </div>;
			if (isWrapperLink) {
				group = <a href={linkAddress} className={linkAddressClass}> {group} </a>;
			}
		}

	}

	return (
		<div className={groupClassNames}>
			<div ref={ref} className={containerClassNames}>
				{group}
			</div>
		</div>
	)
};

export default CoreGroup;
