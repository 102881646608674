/**
 * React dependencies
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import ResponsivePlayer from '../../reposponsive-player';

const Youtube = ( { attributes } ) => {
    const { url } = attributes;

    return (
        <ResponsivePlayer videoUrl={ url } />
    )
};

export default Youtube;
