import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';

const CoreBlock = (props) => {
	const { attrs, reusableBlocks } = props;
	const { ref } = attrs;
	const str = `post:${ref}`;
	const encodedStr = Buffer.from(str).toString("base64");
	const component = reusableBlocks ? reusableBlocks.filter((item) => item.node.id === encodedStr) : false;
	return (<>{component && component[0] && component[0].node && component[0].node.content && ReactHtmlParser(component[0].node.content)}</>);
};

export default CoreBlock;