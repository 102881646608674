/**
 * React dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies
 */
import './style.scss';
import Partnership from '../../home/partnership';

const ContactForm = ({ attrs }) => {
	const [ref, inView] = useInView({
		threshold: 0.2,
		triggerOnce: true,
	});

	const innerContainerClassNames = classname({
		'to-be-faded-in': !inView,
		'fade-in-section': inView
	});

	const meta = {
		partnershipSectionTitle: 'Let’s have a conversation.',
		formOneTitle: 'Publisher',
		formTwoTitle: 'Advertiser',
		formOneFormId: '7b7241c1-3c38-4235-b002-a4772b18294b',
		formOnePortalid: '5731985',
		formOneContent: 'Right now OpenWeb has a limited number of partners we can work with in order to provide the highest quality service to each and every one. Let us know you’re interested and stay informed about how OpenWeb is empowering publishers and advertisers to change online conversations for good.',
		formTwoPortalid: '5731985',
		formTwoFormId: '37d31387-3114-410c-b4b5-6da036aaca5b',
		formTwoContent: '',
	};
	return (
		<section className="wp-block-ow-feature-block-contact-form advertiser-sections conversation-form openweb-hs-form" id="contact-us">
			<div ref={ref} className={innerContainerClassNames}>
				<Partnership meta={meta} isHome={false} attrs={attrs} />
			</div>
		</section>
	);
}

ContactForm.propTypes = {
	attrs: PropTypes.object,
}

export default ContactForm;
