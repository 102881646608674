/**
 * React dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';
import ReactHubspotForm from '@components/react-hubspot-form';

/**
 * Internal dependencies
 */
import './hubspotform-style.scss';
import { set } from 'lodash';

const ContactForm = (props) => {
	const { attrs } = props;
	const { className, formStyle } = attrs;
	const { formId, formAction, cfTagName, cfTagParent } = attrs;
	const portalId = '5731985';

	const [ref, inView] = useInView({
		threshold: 0.2,
		triggerOnce: true,
	});

	const innerContainerClassNames = classname({
		'to-be-faded-in': !inView,
		'fade-in-section': inView,
		[className]: className
	});


	return (
		<section className={`wp-block-ow-feature-block-contact-form form-style-${formStyle || 'generic'}`}>
			<div ref={ref} className={innerContainerClassNames}>
				<ReactHubspotForm
					portalId={portalId}
					formId={formId || "7b7241c1-3c38-4235-b002-a4772b18294b"}
					tagName={cfTagName || "Contact_Submit"}
					formAction={formAction || 'formAction'}
					tagParent={cfTagParent || ""} // provide url of the page
				/>
			</div>
		</section>
	);
}

ContactForm.propTypes = {
	attrs: PropTypes.object,
}

export default ContactForm;
