import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';
import ReactHtmlParser from 'react-html-parser';


import './advancedImage.scss';
import { ELEMENT_TYPES, getKadenceBlock } from '../utils';

export const AdvancedImage = (props) => {
    const { innerHtml, attrs, innerContent } = props;
    // const [ref, inView] = useInView({
    //     threshold: 0.1,
    //     triggerOnce: true,
    // });
    const obj = {
        'kadence-image-container': true,
        // 'ow-to-be-faded-in': !inView,
        // 'ow-fade-in-section': inView,
    };
    const containerClassNames = classname(obj);

    const image = getKadenceBlock(props, ELEMENT_TYPES.IMAGE);
    return (<div className={containerClassNames}>
        {image.style}
        {ReactHtmlParser(innerContent)}
    </div>
    );
};

AdvancedImage.propTypes = {
    attrs: PropTypes.object
}
