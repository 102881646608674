/**
 * React dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies
 */
import './style.scss';
import PostCard from './post-card';
import {sanitize} from "../../../utils/functions";


const RecentPosts = ( {attrs} ) => {
	const [ ref, inView ] = useInView( {
		threshold: 0.2,
		triggerOnce: true,
	} );

	const defaultAttrs = {
		headline: 'From the Open Blog',
		description: 'Our team is sharing information on how you can start preparing.',
		displayCategory: true,
		displayFeaturedImage: true,
		displayPostContent: false,
		addLinkToFeaturedImage: true,
		displayPostDate: true,
		displayAuthor: true,
		selectedPosts: [],
	};

	attrs = {...defaultAttrs, ...attrs};

	const {
		headline,
		description,
		showDescription,
		selectedPosts,
	} = attrs;

	const headerClassNames = classname( {
		'wp-block-ow-feature-block-recent-posts__headline': true,
		'to-be-faded-in': ! inView,
		'fade-in-section': inView
	} );

	return(
		<div className='wp-block-ow-feature-block-recent-posts'>

			<h1 ref={ ref } className={ headerClassNames }
			    dangerouslySetInnerHTML={ {__html: sanitize( headline ) } }
			/>

			{showDescription &&
				<p className='wp-block-ow-feature-block-recent-posts__description'
				    dangerouslySetInnerHTML={ {__html: sanitize( description ) } }
				/>
			}

			<div className='blog-list'>
				{
					selectedPosts.map( ( post, index ) => {
						return (
							<PostCard
								key={index}
								post={ post }
								attrs={attrs}
							/>
						);
					} )
				}
			</div>
		</div>
	);
}

RecentPosts.propTypes = {
	attrs: PropTypes.object
}

export default RecentPosts;
