/**
 * React dependencies
 */
import React from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Youtube from './youtube';

const Embed = ( { attrs } ) => {
    const { providerNameSlug } = attrs;

    const getEmbedType = () => {
        switch ( providerNameSlug ) {
            case 'youtube':
                return Youtube;

            default:
                return null;
        };
    };

    const EmebedType = getEmbedType();

    if ( ! EmebedType ) {
        return null;
    }

    const embedClassName = [ 'wp-block-embed', attrs?.className ];

    return (
        <div className={ classnames( embedClassName ) }>
            <EmebedType attributes={ attrs } />
        </div>
    )
};

export default Embed;
