import React from 'react';
import PropTypes from 'prop-types';
// import classname from 'classnames';

/* Internal dependencies. */
import Embed from '@blocks/embed';
import CoreGroup from '@blocks/core-group';
import CoreCover from '@blocks/core-cover';
import HeroNumbers from '@blocks/hero-numbers';
import CoreColumns from '@blocks/core-columns';
import DefaultBlock from '@blocks/default-block';
import HtmlBlock from '@blocks/core-html';
import RecentPosts from '@blocks/recent-posts';
import ContactForm from '@blocks/contact-form';
import HubspotForm from '@blocks/hubspot-form';
import PublisherContactForm from '@blocks/publisher-contact-form';
import Testimonials from '@blocks/testimonials';
import FeaturedInfo from '@blocks/featured-info';
import MobileScreenSlider from '@blocks/mobile-screen-slider';
import Link from '@blocks/link';
import Quote from '@blocks/quote';
import Tabs from '@blocks/tabs';
import Buttons from '@blocks/buttons';
import CoreList from '@blocks/core-list';
import CoreGallery from '@blocks/core-gallery';
import CoreVideo from '@blocks/core-video';
import CoreBlock from '@blocks/core-block';
import { Lottie } from '@blocks/lottie/Lottie';

import Ticker from '@blocks/ticker';


// KADENCE
import { RowLayout } from '@blocks/kadence/RowLayout/RowLayout';
import { AdvancedHeader } from '@blocks/kadence/AdvancedHeader/AdvancedHeader';
import { KadenceColumn } from '@blocks/kadence/Column/Column';
import { AdvancedButton } from '@blocks/kadence/AdvancedButtton/AdvancedButton';
import { AdvancedImage } from '@blocks/kadence/AdvancedImage/AdvancedImage';
import { AdvancedLottie } from '@blocks/kadence/AdvancedLottie/AdvancedLottie';
import { AdvancedSpacer } from '@blocks/kadence/AdvancedSpacer/AdvancedSpacer';



/**
 * Internal dependencies
 */


export function MapBlocks(name, reusableBlocks, parsedContent, content) {
    switch (name) {
        case 'kadence/rowlayout':
            return (props) => <RowLayout {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'kadence/column':
            return (props) => <KadenceColumn {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'kadence/advancedheading':
            return (props) => <AdvancedHeader {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'kadence/advancedbtn':
            return (props) => <AdvancedButton {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'kadence/image':
            return (props) => <AdvancedImage {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;
        case 'kadence/spacer':
            return (props) => <AdvancedSpacer {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'core/block':
            return (props) => <CoreBlock {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'kadence/lottie':
            return (props) => <AdvancedLottie {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'ow-feature-block/lottie-player':
            return Lottie;

        case 'ow-feature-block/ticker':
            return Ticker;

        case 'ow-feature-block/testimonials':
            return Testimonials;

        case 'core/group':
            return (props) => <CoreGroup {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'core/gallery':
            return CoreGallery
        case 'core/html':
            return HtmlBlock;

        case 'core/cover':
            return CoreCover

        case 'core/columns':
            return (props) => <CoreColumns {...props} reusableBlocks={reusableBlocks} parsedContent={parsedContent} content={content} />;

        case 'core/embed':
            return Embed

        case 'core/buttons':
            return Buttons;

        case 'core/list':
            return CoreList;

        case 'core/video':
            return (props) => <CoreVideo {...props} />;

        case 'ow-feature-block/hero-numbers':
            return HeroNumbers;

        case 'ow-feature-block/featured-info':
            return FeaturedInfo;

        case 'ow-feature-block/recent-posts':
            return RecentPosts;

        case 'ow-feature-block/contact-form':
            return ContactForm;

        case 'ow-feature-block/hubspot-form':
            return HubspotForm;

        case 'ow-feature-block/publisher-contact-form':
            return PublisherContactForm;

        case 'ow-feature-block/link':
            return Link;

        case 'ow-feature-block/quote':
            return Quote;

        case 'ow-feature-block/mobile-screen-slider':
            return MobileScreenSlider;

        case 'ow-feature-block/tabs':
            return Tabs;


        default:
            return DefaultBlock;
    }
};


export const collectInnerBlocks = (innerBlocks, reusableBlocks, parsedContent, content) => {
    if (!innerBlocks || innerBlocks.length === 0) return <></>;

    return innerBlocks.map((innerBlock, index) => {
        const name = innerBlock.blockName;

        const Component = MapBlocks(name, reusableBlocks, parsedContent, content);

        return (
            <Component
                key={`${name}-${index}`}
                {...innerBlock}
            />
        );
    });
}




MapBlocks.propTypes = {
    name: PropTypes.string
}
