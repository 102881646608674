/**
 * React dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

/**
 * Internal dependencies
 */
import './style.scss';
import RightArrowIcon from "../../icons/right-arrow-icon";

const Link = ({ attrs }) => {
	const defaultAttrs = {
		showIcon: true,
		align: null,
		className: '',
	};

	const {
		title, url, showIcon, target, nofollow, sponsored, align, className
	} = { ...defaultAttrs, ...attrs };

	let rel = '';

	if (nofollow) {
		rel += ' nofollow';
	}

	if (sponsored) {
		rel += ' sponsored';
	}

	const linkClassNames = classname({
		[className]: true,
		'wp-block-ow-feature-block-link': true,
		'has-align': align,
		[`align-${align}`]: align,
	});

	return (
		<a
			className={linkClassNames}
			href={url}
			target={target ? '_blank' : ''}
			rel={target ? "noreferrer" : rel}
		>
			<span>
				<span dangerouslySetInnerHTML={{ __html: title }} />
				{showIcon && <RightArrowIcon />}
			</span>
		</a>
	);
};

Link.propTypes = {
	attrs: PropTypes.object
}

export default Link;
