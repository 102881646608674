/**
 * External dependencies.
 */
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';

/**
 * Internal dependencies.
 */
import Image from './../../image/index';
import LinkWithArrow from '../../link-with-arrow';
import { pageSlug, sanitize } from '../../../utils/functions';

import './style.scss';

const LAYOUT_TYPE = {
	VERTICAL: 'vertical',
	SLIDER: 'slider'
}

/**
 * Home Testimonials Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const Testimonials = ({ attrs }) => {
	const [ref, inView] = useInView({
		threshold: 0.01,
	});
	const { testimonials, pageLinkText, pageLink, layout } = attrs;
	const sliderRef = createRef();
	const isVertical = layout === LAYOUT_TYPE.VERTICAL;
	const isSlider = !isVertical;
	useEffect(() => {
		if (isSlider) {
			if (inView) {
				sliderRef.current.slickPlay();
			} else {
				sliderRef.current.slickPause();
			}
		}
	}, [inView]); // eslint-disable-line

	const settings = {
		autoplay: true,
		arrows: false,
		dots: false,
		infinite: true,
		speed: 500,
		autoplaySpeed: 5000, // Change slide after 5 seconds.
		slidesToScroll: 1,
		slidesToShow: 3,
		centerPadding: '0',
		swipeToSlide: true,
		touchThreshold: 100,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 3,
				}
			},
		]
	};

	if (isVertical) {
		return (<section className="home-sections home-testimonials testimonials-layout-vertical">
			<div className="home-testimonials__lists">
				{testimonials.map((testimonial, index) => (
					<div key={index} className="home-testimonials__list">
						<div className="home-testimonials__header">
							<figure className="home-testimonials__logo">
								<Image
									className="home-testimonials__logo-image"
									altText={testimonial.author_name}
									lazy={false}
									title={testimonial.author_name}
									sourceUrl={testimonial.logo[0]}
								/>
							</figure>
							<div className="home-testimonials__content" dangerouslySetInnerHTML={{ __html: sanitize(testimonial.content) }} />
						</div>
						<div className="home-testimonials__meta">
							<figure className="home-testimonials__author">
								<Image
									className="home-testimonials__author-image"
									altText={testimonial.author_name}
									lazy={false}
									title={testimonial.author_name}
									sourceUrl={testimonial.author_profile_picture[0]}
								/>
							</figure>
							<div className="home-testimonials__details">
								<h4>{testimonial.author_name}</h4>
								<p>{testimonial.author_designation}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>);
	} else {
		return (
			<section ref={ref} className="home-sections home-testimonials">
				<div className="home-testimonials__lists">
					<button className="home-testimonials__arrows home-testimonials__arrow-prev" onClick={() => sliderRef.current.slickPrev()} >Left</button>
					<Slider {...settings} ref={sliderRef} >
						{testimonials.map((testimonial, index) => (
							<div key={index} className="home-testimonials__list">
								<div className="home-testimonials__header">
									<figure className="home-testimonials__logo">
										<Image
											className="home-testimonials__logo-image"
											altText={testimonial.author_name}
											lazy={false}
											title={testimonial.author_name}
											sourceUrl={testimonial.logo[0]}
										/>
									</figure>
									<div className="home-testimonials__content" dangerouslySetInnerHTML={{ __html: sanitize(testimonial.content) }} />
								</div>
								<div className="home-testimonials__meta">
									<figure className="home-testimonials__author">
										<Image
											className="home-testimonials__author-image"
											altText={testimonial.author_name}
											lazy={false}
											title={testimonial.author_name}
											sourceUrl={testimonial.author_profile_picture[0]}
										/>
									</figure>
									<div className="home-testimonials__details">
										<h4>{testimonial.author_name}</h4>
										<p>{testimonial.author_designation}</p>
									</div>
								</div>
							</div>
						))}
					</Slider>
					<button className="home-testimonials__arrows home-testimonials__arrow-next" onClick={() => sliderRef.current.slickNext()} >Right</button>
				</div>
				<div className="home-testimonials__more">
					<LinkWithArrow text={pageLinkText} link={pageSlug(pageLink)} />
				</div>
			</section>
		);
	}

};

Testimonials.propTypes = {
	attrs: PropTypes.object,
	pageLink: PropTypes.string,
	pageLinkText: PropTypes.string,
};

Testimonials.defaultProps = {
	attrs: {
		testimonials: [],
		pageLink: '',
		pageLinkText: '',
	}
};

export default Testimonials;
