import React from 'react';
import PropTypes from 'prop-types';

import './advancedSpacer.scss';
import { ELEMENT_TYPES, getKadenceBlock } from '../utils';

export const AdvancedSpacer = (props) => {
    const spacer = getKadenceBlock(props, ELEMENT_TYPES.SPACER);
    return (<>
        {spacer && spacer.style && spacer.style}
        {spacer && spacer.component && spacer.component}
    </>);
};

AdvancedSpacer.propTypes = {
    attrs: PropTypes.object
}
