/**
 * React dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './style.scss';
import UpArrowIcon from "../../icons/up-arrow-icon";

const HeroNumbers = ({ attrs }) => {
	const defaultAttrs = {
		title: '23.24%',
		description: 'Lift in time on sites',
		showTopBar: true,
		showIcon: true,
		arrowUpward: true,
	};

	const {
		title, description, showTopBar, showIcon, arrowUpward, icon = 'arrow'
	} = { ...defaultAttrs, ...attrs };

	const [heroIcon, setHeroIcon] = useState('');

	useEffect(() => {
		let element = '';

		switch (icon) {
			case 'plus':
				element = <span style={{ color: '#00e600' }}>+</span>;
				break;

			default:
				element = <UpArrowIcon />;
				break;
		};

		setHeroIcon(element);
	}, [icon]);

	const className = classnames({
		'title': true,
		'border-top': showTopBar,
		'arrow-downward': !arrowUpward,
	});

	return (
		<div className='wp-block-ow-feature-block-hero-numbers'>
			<div className={className}>
				{showIcon && heroIcon}
				{title}
			</div>
			<p className='description'>{description}</p>
		</div>
	);
}

HeroNumbers.propTypes = {
	attrs: PropTypes.object,
	title: PropTypes.string,
	description: PropTypes.string,
	showTopBar: PropTypes.bool,
	showIcon: PropTypes.bool,
	arrowUpward: PropTypes.bool,
	icon: PropTypes.string,
}

export default HeroNumbers;
